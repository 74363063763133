<template>
  <el-dialog
    title="选择道路"
    :visible.sync="modelDialog"
    custom-class="model-dialog"
    width="1000px"
    @close="handleClose"
  >
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="form"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Road_name')">
            <el-input
              class="inline-input"
              clearable
              v-model.trim="formInline.parkName"
              placeholder="请输入道路名称"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.charge_type')">
            <el-select v-model.trim="formInline.scopeId" filterable placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code + ''"
                :key="value.code"
                v-for="value in parkScope"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >搜索</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          ref="multipleTable"
          v-loading="loading"
          stripe
          :data="tableData"
          @row-click="handleSelectionChange"
        >
          <el-table-column align="center" width="55">
            <template slot-scope="scope">
              <el-radio class="single-radio" v-model="selectParkId" :label="scope.row.parkId"
                >&nbsp;</el-radio
              >
            </template>
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submitData">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "ChooseRoad",
  data() {
    return {
      modelDialog: false,
      loading: false,
      areaIds: "",
      areaId: "",
      streetId: "",
      total: 0,
      pageSize: 15,
      page: 1,
      selectParkId: "",
      tableData: [],
      parkScope: [],
      tenantList: [],
      selectList: [],
      formInline: {
        areaId: "",
        operationId: "",
        scopeId: "",
        parkName: "",
        slaveRelations: "0,2",
      },
      tableCols: [
        {
          prop: "parkAlias",
          label: this.$t("list.Road_coding"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "200",
        },
        {
          prop: "scopeName",
          label: this.$t("list.charge_type"),
          width: "120",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
      ],
    };
  },
  methods: {
    open(data) {
      if (data.length == 1) {
        this.selectParkId = data[0].parkId;
      } else {
        this.selectParkId = '';
      };
      this.modelDialog = true;
      this.selectList = data;
      this.queryTypeList();
      this.getTenantList();
      this.searchData();
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkScope = data["parkScope"];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleSelectionChange(row) {
      this.selectParkId = row.parkId;
    },
    // 搜索条件初始化
    searchInit() {
      this.formInline.areaId = this.streetId ? this.streetId : this.areaId;
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.searchInit();
      let url = "/acb/2.0/park/list";
      let data = JSON.parse(JSON.stringify(this.formInline));
      data.areaId = "";
      if (this.$refs.cascader) {
        data.areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...data,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    submitData() {
      if (!this.selectParkId) {
        this.$message({ type: "warning", message: "请选择一条道路" });
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios
            .post("/acb/2.0/berth/updateBerthShare", {
              data: {
                parkId: this.selectParkId,
                berthListId: this.selectList?.map((v) => v.berthId).join(),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "success",
                  message: "分配成功",
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.modelDialog = false;
              this.loading = false;
            });
        }
      });
    },
    handleClose() {
      this.modelDialog = false;
    },
  },
};
</script>
<style lang="stylus" rel="stylesheet/stylus">
.model-dialog {
  .tableWrapper .el-table {
    max-height: 600px;
    overflow: auto;
    .single-radio {
      .el-radio__label {
        padding-left: 0;
      }
    }
  }
  .el-form-item:last-child {
    margin-right: 0;
    .el-button {
      margin-right: 0 !important;
    }
  }
  .el-form-item:nth-child(4) {
    .el-input__inner {
      width: 188px;
    }
  }
  .el-table .el-input__inner, .searchWrapper .el-input__inner {
    width: 130px;
  }
}
</style>
