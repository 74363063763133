<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          label-width="80px"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t("searchModule.Query_Table") }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  v-model.trim="formInline.berthCode"
                  placeholder="请输入泊位编号"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <el-input
                  v-model.trim="formInline.parkName"
                  placeholder="请输入道路名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.berthState">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in statusList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.distribution')">
                <el-select v-model.trim="formInline.shareType">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="未分配" value="1"></el-option>
                  <el-option label="已分配" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Berth_type')">
                <el-select v-model.trim="formInline.berthType">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in berthTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Service_label')">
                <el-select v-model.trim="formInline.berthTool" multiple collapse-tags>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in tagList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.region')">
                <a-cascader ref="cascader" @change="handleAreaChange"></a-cascader>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.park_name')">
            <el-autocomplete
              class="inline-input"
              v-model="modelvalue"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              value-key="parkName"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Merchant')" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_number')">
                <el-input
                  v-model.trim="formInline.equipmentCode"
                  placeholder="请输入设备编号"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
              <el-button type="info" plain icon="el-icon-delete" @click="resetForm()">{{
                $t("button.reset")
              }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" @click="addUpdata">{{
                $t("button.synchronized_berth")
              }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <div class="total-wrapper bgFFF">
        <div class="item-wrapper" v-for="item in totalList.filter((v) => v.num)" :key="item.title">
          <div class="text">{{ item.title }}</div>
          <span class="unit"
            ><span class="bold-wrap">{{ item.num }}</span
            >个</span
          >
        </div>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <div class="batch-wrap" v-show="selectList.length">
          已选择 <span class="batch-num">{{ selectList.length }}</span> 项
          <el-button type="primary" @click="assignRoad(selectList)">分配道路</el-button>
        </div>
        <el-table
          header-cell-class-name="header-call-style"
          ref="multipleTable"
          v-loading="loading"
          row-key="berthId"
          :data="tableData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            reserve-selection
            align="center"
            :selectable="checkSelectable"
            width="55"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :min-width="item.minWidth"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            fixed="right"
            :label="this.$t('list.operation')"
            align="center"
            width="80"
            v-if="$route.meta.authority.button.view"
          >
            <template slot-scope="{ row }">
              <el-button
                :disabled="row.berthState == 1"
                type="text"
                @click="assignRoad([row])"
                style="padding: 0"
                >分配道路</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <choose-road ref="chooseRoadRef" @success="handleSuccess"></choose-road>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
import ChooseRoad from "./chooseRoad.vue";
export default {
  name: "parkList",
  components: { ChooseRoad },
  data() {
    return {
      cardLists: [
        { title: "泊位数", number: 0, unit: "个" },
        { title: "在场车数", number: 0, unit: "辆" },
      ],
      serviceAttr: [],
      berthTypeList: [],
      selectList: [],
      statusList: [
        {
          code: 1,
          desc: "上线",
        },
        {
          code: 2,
          desc: "下线",
        },
      ],
      tagList: [
        {
          code: 1,
          desc: "共享泊位",
        },
        {
          code: 2,
          desc: "潮汐泊位",
        },
        {
          code: 3,
          desc: "充电泊位",
        },
        {
          code: 4,
          desc: "残疾人泊位",
        },
      ],
      entryPic: [],
      exitPic: [],
      dialogTableVisible: false,
      dialogVisible: false,
      checked: false,
      checked1: false,
      parkdetaildata: {},
      temParkData: {},
      modelvalue: "",
      streetList: [],
      street: "",
      areaId: "",
      streetId: "",
      area: "",
      state1: "",
      parkType: [],
      parkScope: [],
      commercialStatus: [],
      parkData: [],
      index: 0,
      total: 0,
      parkPage: 1,
      parkPageSize: 15,
      pageSize: 15,
      page: 1,
      loading: false,
      areaList: [],
      parkList: [],
      parkinfoOne: [],
      tenantList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          minWidth: "120",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          minWidth: 200,
          formatter: (row) => {
            return row.childParkName ? `${row.parkName}-${row.childParkName}` : row.parkName;
          },
        },
        {
          prop: "berthState",
          label: this.$t("list.state"),
          formatter: (row) => {
            return ["", "上线", "下线"][row.berthState];
          },
        },
        {
          prop: "berthType",
          label: this.$t("list.Berth_type"),
          formatter: (row, column, cellValue, index) => {
            for (let i = 0; i < this.berthTypeList.length; i++) {
              if (this.berthTypeList[i].code === cellValue) {
                return this.berthTypeList[i].desc;
              }
            }
          },
        },
        {
          prop: "berthTag",
          label: this.$t("list.Service_label"),
          width: "200",
          formatter: (row, column) => {
            let arr = [];
            if (row.serviceTypeTide) {
              arr.push(this.$t("list.Tidal_berth"));
            }
            if (row.serviceTypeShare) {
              arr.push(this.$t("list.Shared_berth"));
            }
            if (row.charge) {
              arr.push(this.$t("list.Charging_berth"));
            }
            if (row.berthDisability) {
              arr.push(this.$t("list.Disabled_berth"));
            }
            return arr.join("、");
          },
        },
        {
          prop: "berthType1",
          label: this.$t("list.latitude_and_longitude"),
          width: "200",
          formatter: (row, column, cellValue, index) => {
            for (let i = 0; i < this.berthTypeList.length; i++) {
              if (this.berthTypeList[i].code === cellValue) {
                return this.berthTypeList[i].desc;
              }
            }
          },
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          minWidth: "180",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant"),
          minWidth: "180",
        },
        {
          prop: "equipmentCode",
          label: this.$t("list.Equipment_number"),
          minWidth: "180",
          formatter: (row) => {
            return row.equipments.length ? row.equipments[0].equipmentCode : "-";
          },
        },
      ],
      dataList: [],
      tableData: [],
      totalObj: {},
      totalList: [],
      formInline: {
        berthCode: "",
        berthType: "",
        berthState: "",
        shareType: "",
        berthTool: [],
        areaId: "",
        parkId: "",
        operationId: "",
        parkType: "",
        commercialStatus: "",
        scopeId: "",
        equipmentCode: "",
        parkRecordId: "",
        parkName: "",
        areaIds: [],
      },
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    handleSuccess() {
      this.$refs.multipleTable.clearSelection();
      this.searchData();
    },
    handleSelectionChange(val) {
      this.selectList = val;
    },
    checkSelectable(row) {
      return row.berthState == 2;
    },
    addUpdata() {
      this.$router.push("/parkEocList");
      this.$setAuthority("parkEocList", this.$route.meta.authority.subPage.modifyAudit);
    },
    // 选择区域
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.formInline.areaId = areaId;
    },

    // 泊位类型
    getSelectedList() {
      this.$axios.get("/acb/2.0/berth/getAddOption").then((res) => {
        this.berthTypeList = res.value.berthType;
      });
    },
    closeDialog() {
      this.parkdetaildata = {};
    },
    resetForm() {
      this.formInline = {
        berthType: "",
        berthState: "",
        shareType: "",
        areaId: "",
        parkId: "",
        operationId: "",
        parkType: "",
        commercialStatus: "",
        scopeId: "",
        streetId: "",
        berthTool: [],
      };
      this.$refs.cascader.clear();
      this.modelvalue = "";
      this.temParkData = {};
      this.searchData();
    },
    buttonDetail() {
      this.dialogTableVisible = false;
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: this.modelvalue,
            dataSource: 1,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            //  this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    areaChange(areaId) {
      this.parkPage = 1;
      this.streetList = [];
      this.streetId = "";
      this.setParkNull();
      if (!areaId) return;
      this.getStreet(areaId);
    },
    streetChange(streetId) {
      this.parkPage = 1;
      this.setParkNull();
      this.getPark(streetId);
    },
    getStreet(areaId) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.streetList = res.value;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    queryPark() {
      this.parkPage++;
      this.getPark(this.area);
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: this.parkPage,
            pageSize: this.parkPageSize,
            dataSource: 1,
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (this.parkPage == 1) {
              this.$refs.select.setNull();
            }
            this.parkData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkType = data["parkType"];
          this.commercialStatus = data["commercialStatus"];
          this.parkScope = data["parkScope"];
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    // 分配道路
    assignRoad(list) {
      this.$refs.chooseRoadRef.open(list);
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },

    /**
     * 获取统计结果
     */
    getTotalList() {
      let url = "/acb/2.0/selfParkingExit/payExit";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          console.log("res", res);
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      // this.formInline.areaId = this.streetId ? this.streetId : this.areaId;
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }

      let url = "/acb/2.0/berth/queryBerth";
      this.$axios
        .post(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
            berthTool: this.formInline.berthTool.join(),
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = (res.value && res.value.total * 1) || 0;
            this.tableData = (res.value && res.value.list) || [];
            this.totalList = [
              {
                title: "泊位数",
                num: res.value?.total,
              },
              {
                title: "上线泊位",
                num: res.value?.berthZ,
              },
              {
                title: "下线泊位",
                num: res.value?.berthL,
              },
              {
                title: "视频泊位",
                num: res.value?.berthS,
              },
              {
                title: "PDA泊位",
                num: res.value?.berthP,
              },
              {
                title: "地磁泊位",
                num: res.value?.berthD,
              },
              {
                title: "视频桩泊位",
                num: res.value?.berthG,
              },
              {
                title: "潮汐泊位",
                num: res.value?.berthX,
              },
              {
                title: "共享泊位",
                num: res.value?.berthN,
              },
              {
                title: "充电泊位",
                num: res.value?.chargeC,
              },
              {
                title: "残疾人泊位",
                num: res.value?.berthC,
              },
            ];
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  beforeDestroy() {},
  created() {
    this.getSelectedList();
    this.queryTypeList();
    this.getTenantList();
    this.searchData();
  },
  computed: {},
  mounted() {
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
};
</script>
<style>
.table-tooltip-tip {
  white-space: pre-wrap;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
// ::v-deep .el-button.operation {
// padding: 0
// }
.wrap-text {
  white-space: pre-line;
  text-align: center;
}

.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }

  .batch-wrap {
    display: flex;
    align-items: center;
    margin: 0 0 10px 20px;

    .batch-num {
      color: #409EFF;
    }

    .el-button {
      margin-left: 10px;
    }
  }
}

.detailButton {
  width: 4.5rem;
  height: 2.5rem;
  border: 1px solid #E3E5EB;
  background: white;
  border-radius: 3px;
  position: relative;
  left: 42%;
  margin-top: 9rem;
  cursor: pointer;
}

.parkdetail {
  width: 100%;
  height: 2.5rem;
  margin: 0 auto;
  margin-bottom: 1rem;

  div {
    width: 100%;
  }

  p {
    float: left;
  }

  p:last-child {
    text-align: left;
  }

  p:first-child {
    width: 26%;
    text-align: right;
  }

  .ParkBerthul {
    border: 1px solid #D2D2D2;
    width: 16rem;
    /* height: auto; */
    height: 11.01rem;
    margin-left: 7.8rem;
    margin-top: -1rem;
    border-radius: 2px;
  }

  .ParkBerthul li {
    height: 35px;
    line-height: 35px;
    background: #EDF1F4;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }

  .ParkBerthul li:last-child {
    border-bottom: 0;
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.total-wrapper {
  padding: 0 20px;

  .item-wrapper {
    min-width: 88px;
    margin: 0 16px 16px 0;
    padding: 8px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}
</style>
